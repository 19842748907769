import React, { useState, useEffect } from 'react';
//import Table from '../components/tables/Table';
import Table from '../components/tables/Table(virtual)';
import NCAccounts from '../components/NCAccounts';
import Domains from '../components/Domains';
import Users from '../components/Users';
import ParentComp from '../components/ParentComp';
import { useAuth } from '../contexts/AuthContext';
import {
  TextField,
  Button,
} from '@mui/material';
import { getTeamsList, getParentList } from '../services/api';

const AdminPage = ({currentPage}) => {
  const { user, /*loading*/ } = useAuth();
  const [searchValue, setSearchValue] = useState('');
  const [activeTab, setActiveTab] = useState('AllDomains');
  const [menuTeamOptions, setMenuTeamOptions] = useState([]);
  const [menuParentOptions, setMenuParentOptions] = useState([]);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const teams = await getTeamsList();
        const groups = await getParentList();
        setMenuTeamOptions(teams);
        setMenuParentOptions(groups);
      } catch (error) {
        console.error('Failed to fetch teams', error);
      }
    };

    fetchTeams();
  }, []);

  const activeButtonStyle = {
    backgroundColor: '#e9eaeb',
  };


  return (
    <>
      {currentPage === 'NCAccounts' && 
          <NCAccounts />
      }
      {currentPage === 'Domains' && 
          <Domains />
      }
      {currentPage === 'Users' && 
          <Users menuTeamOptions={menuTeamOptions} />
      }
      {currentPage === 'MasterSheet' && 
          <>
          <TextField
        fullWidth
        label="Global search"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        size="small"
        className={'global-search'}
        variant="filled"
      />
      
      <Table
            tableName={activeTab} // Передаем имя таблицы в зависимости от выбранной вкладки
            currentUser={user.username}
            userTeam={user.team}
            searchValue={searchValue}
            menuTeamOptions={menuTeamOptions}
            menuParentOptions={menuParentOptions}
          />
      <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
      <Button 
        color="inherit"
        size="medium"
        sx={{ height: '36px', ...(activeTab === 'AllDomains' && activeButtonStyle) }}
        onClick={() => setActiveTab('AllDomains')}
      >
        All Domains
      </Button>
      <Button
        color="inherit"
        size="medium"
        sx={{ height: '36px', ...(activeTab === 'Health' && activeButtonStyle) }}
        onClick={() => setActiveTab('Health')}
      >
        Health
      </Button>
      <Button
        color="inherit"
        size="medium"
        sx={{ height: '36px', ...(activeTab === 'IEX' && activeButtonStyle) }}
        onClick={() => setActiveTab('IEX')}
      >
        IEX
      </Button>
      <Button
        color="inherit"
        size="medium"
        sx={{ height: '36px', ...(activeTab === 'Polaris' && activeButtonStyle) }}
        onClick={() => setActiveTab('Polaris')}
      >
        Polaris
      </Button>
      <Button
        color="inherit"
        size="medium"
        sx={{ height: '36px', ...(activeTab === 'Dubai' && activeButtonStyle) }}
        onClick={() => setActiveTab('Dubai')}
      >
        Dubai
      </Button>
      <Button
        color="inherit"
        size="medium"
        sx={{ height: '36px', ...(activeTab === 'Crypto' && activeButtonStyle) }}
        onClick={() => setActiveTab('Crypto')}
      >
        Crypto
      </Button>
      <Button
        color="inherit"
        size="medium"
        sx={{ height: '36px', ...(activeTab === 'Landing' && activeButtonStyle) }}
        onClick={() => setActiveTab('Landing')}
      >
        Landing
      </Button>
      <Button
        color="inherit"
        size="medium"
        sx={{ height: '36px', ...(activeTab === 'Academy' && activeButtonStyle) }}
        onClick={() => setActiveTab('Academy')}
      >
        Academy
      </Button>
      <Button
        color="inherit"
        size="medium"
        sx={{ height: '36px', ...(activeTab === 'NotSet' && activeButtonStyle) }}
        onClick={() => setActiveTab('NotSet')}
      >
        Not Set
      </Button>
      </div>
          </>
      }
      {currentPage === 'Parent' && 
          <ParentComp/>
      }
    </>
  );
};

export default AdminPage;