import {
  fetchTableRequest,
  fetchTableSuccess,
  fetchTableFailure,
  modifyTableData,
  addTableRow,
  deleteTableRow,
} from '../reducers/tableReducer';
import { AllTable,
  getIEXTable,
  getHealthTable,
  getPolarisTable,
  getDubaiTable,
  getCryptoTable,
  getLandingTable,
  getAcademyTable,
  notSetTable,
  getManagerTable,
  getMarketerTable,
  //actions with table
  updateTable,
  addNewRow,
  deleteRowFromTable } from '../services/api';

export const subscribeToTableUpdates = (socket, tableId) => {
  return (dispatch) => {
    // Отправляем событие на сервер для подписки
    socket.emit('subscribeToTable', { target: tableId });
    
  };
};

export const unsubscribeFromAllTablesUpdates = (socket) => {
  return (dispatch) => {
    // Отправляем событие на сервер для подписки
    socket.emit('unsubscribeFromAllTables');
    
  };
};

export const fetchTableData = (tableName, team=null, marketer=null) => {
  return async (dispatch) => {
    dispatch(fetchTableRequest());

    try {
      let response;
      switch (tableName) {
        case 'AllDomains':
          response = await AllTable();
          break;
        case 'Health':
          response = await getHealthTable();
          break;
        case 'IEX':
          response = await getIEXTable();
          break;
        case 'Polaris':
          response = await getPolarisTable();
          break;
        case 'Dubai':
          response = await getDubaiTable();
          break;
        case 'Crypto':
          response = await getCryptoTable();
          break;
        case 'Landing':
          response = await getLandingTable();
          break;
        case 'Academy':
          response = await getAcademyTable();
          break;
        case 'NotSet':
          response = await notSetTable();
          break;
        case 'Manager':
          response = await getManagerTable(team);
          break;
        case 'Marketer':
          response = await getMarketerTable(marketer);
          break;
        default:
          throw new Error(`Unknown table name: ${tableName}`);
      }

      const tableDataWithIndex = response.map((row, index) => ({
        ...row,
        index: index + 1, // Порядковый номер начинается с 1
        lines: Math.max(
          ...Object.values(row).map(value =>
            typeof value === 'string' ? value.split('\n').length : 1
          )
        ) + 1,
      }));
      dispatch(fetchTableSuccess({ tableData: tableDataWithIndex, tableName }));
    } catch (error) {
      dispatch(fetchTableFailure(error.message));
    }
  };
};

export const updateTableData = (id, columnName, newValue) => {
  return async (dispatch) => {
    try{
      await updateTable(id, columnName, newValue);//api update
      dispatch(modifyTableData({ id, columnName, newValue }));//update in table
      //dispatch(fetchTableData());//upgrade table from DB
    }
    catch (error){
      console.error('Error updating data:', error);
    }
  };
};

export const updateLocalTableData = (id, columnName, newValue) => {
  return async (dispatch) => {
    try{
      dispatch(modifyTableData({ id, columnName, newValue }));//update in table
    }
    catch (error){
      console.error('Error updating data locally:', error);
    }
  };
};


export const addNewTableRow = (newRowData) => {
  return async () => {
    try {
      await addNewRow(newRowData);
    } catch (error) {
      console.error('Error adding new row:', error);
    }
  };
};

export const addNewlocalTableRow = (newRowData) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const tableData = state.table.tableData || [];
      const maxIndex = tableData.reduce((max, row) => (row.index > max ? row.index : max), 0);
      const newRowDataWithDetails = { ...newRowData, index: maxIndex + 1 };

      // Подсчет количества строк для нового ряда
      newRowDataWithDetails.lines = Math.max(
        ...Object.values(newRowData).map(value =>
          typeof value === 'string' ? value.split('\n').length : 1
        )
      ) + 1; // Увеличиваем на 1 для учета заголовка

      dispatch(addTableRow(newRowDataWithDetails));
    } catch (error) {
      console.error('Error apply new row:', error);
    }
  };
};

export const deleteByIdTableRow = (id) => {
  return async (dispatch) => {
    try {
      await deleteRowFromTable(id);
      dispatch(deleteTableRow({id: id}));
      //dispatch(fetchTableData());
    } catch (error) {
      console.error('Error adding new row:', error);
    }
  };
};

export const deleteByIdTableRowLocal = (id) => {
  return async (dispatch) => {
    try {
      dispatch(deleteTableRow({id: id}));
      //dispatch(fetchTableData());
    } catch (error) {
      console.error('Error adding new row:', error);
    }
  };
};
