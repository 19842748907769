// src/socket.js
import { io } from "socket.io-client";
import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { 
  updateLocalTableData,
  addNewlocalTableRow,
  deleteByIdTableRowLocal,
 } from '../actions/tableActions';
 import { 
  updateLocalAcademyTableData,
 } from '../actions/tableAcademyActions';
 import { 
  updateAllDomainsLocal,
 } from '../actions/AcademyAllActions';

const SocketContext = createContext(null);

const socket = io(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}`, {
  withCredentials: true,
  transports: ['websocket'],
});

// Провайдер для передачи сокета
export const SocketProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    // Обработка событий сокета
    socket.on("connect", () => {
      console.log("Socket connected:", socket.id);
    });

    socket.on("disconnect", () => {
      console.log("Socket disconnected");
    });

    socket.on("error", (error) => {
      console.error("WebSocket error:", error);
    });

    socket.on("message", (message) => {
      console.log("New msg:", message);
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    socket.on('tableUpdate', (data) => {
      const { action, id, columnName, value, fullRow } = data;
      switch (action) {
        case 'update':
          dispatch(updateLocalTableData(id, columnName, value));
          break;
        case 'adminAdd':
          const { newRow } = data;
          dispatch(addNewlocalTableRow(newRow));
          break;
        case 'delete':
          dispatch(deleteByIdTableRowLocal(id));
          break;
        case 'newDomainInTeam':
          dispatch(addNewlocalTableRow(fullRow));
          break;
        case 'newDomainInPrivate':
          dispatch(addNewlocalTableRow(fullRow));
          break;
        case 'update-exam':
          dispatch(updateLocalAcademyTableData(id, columnName, value));
          break;
        case 'update-all':
          dispatch(updateAllDomainsLocal(id, columnName, value));
          break;

        default:
          console.warn(`Unknown action: ${action}`);
          break;
      }
    });

    socket.on('ping', () => {
      socket.emit('pong');
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("error");
      socket.off("message");
      socket.off("tableUpdate");
      socket.off('ping');
    };
  }, [dispatch]);

  return (
    <SocketContext.Provider value={{ socket, messages }}>
      {children}
    </SocketContext.Provider>
  );
};

// Хук для использования сокета
export const useSocket = () => {
  return useContext(SocketContext);
};
