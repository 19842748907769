import React, { useState } from 'react';
import axios from 'axios';

const ARecordsChecker = () => {
  const [domainsInput, setDomainsInput] = useState('');
  const [domainsInfo, setDomainsInfo] = useState([]);

  const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}`,
    withCredentials: true,
  });

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      const domainsArray = domainsInput.split('\n').map((domain) => domain.trim()).filter(Boolean);
      const response = await api.post('/api/a-records-check', { domains: domainsArray });
      setDomainsInfo(response.data);
    } catch (error) {
      console.error('Error fetching A records:', error);
    }
  };

  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>A Records Checker</h1>
      <form onSubmit={handleFormSubmit}>
        <label htmlFor="domains">Enter Domains:</label>
        <br />
        <textarea
          id="domains"
          name="domains"
          rows="5"
          cols="50"
          value={domainsInput}
          onChange={(e) => setDomainsInput(e.target.value)}
          style={{ width: '100%', padding: '8px', boxSizing: 'border-box' }}
        />
        <br />
        <input type="submit" value="Check A Records" style={{ marginTop: '8px' }} />
        <br />
        <br />
      </form>
      <div style={{ overflowX: 'auto' }}>
        {domainsInfo.length > 0 && (
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '16px' }}>
            <thead>
              <tr style={{ backgroundColor: '#f2f2f2', textAlign: 'center' }}>
                <th style={tableHeaderStyle}>Domain Name</th>
                <th style={tableHeaderStyle}>A Records</th>
              </tr>
            </thead>
            <tbody>
              {domainsInfo.map((domain, index) => (
                <tr key={index}>
                  <td style={tableCellStyle}>{domain.domain}</td>
                  <td style={tableCellStyle}>
                    {domain.error ? (
                      <span style={{ color: 'red' }}>{domain.error}</span>
                    ) : (
                      <pre style={preStyle}>{domain.addresses.join('\n')}</pre>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

const tableCellStyle = {
  border: '1px solid #676767',
  padding: '8px',
  textAlign: 'center',
};

const tableHeaderStyle = {
  border: '1px solid #676767',
  backgroundColor: '#f2f2f2',
  padding: '8px',
  textAlign: 'center',
};

const preStyle = {
  margin: '0',
  whiteSpace: 'pre-wrap',
};

export default ARecordsChecker;
