import {
  fetchTableRequest,
  fetchTableSuccess,
  fetchTableFailure,
  modifyTableData,
  addTableRow,
  deleteTableRow,
} from '../reducers/AcademyAllReducer';
import { 
  academyDomainsAll,
  updateAcademyAll,
  addNewAcademyAll,
  deleteRowFromAcademyAll,
  allAcademyDeleteAll,
  //checkDomain
 } from '../services/api';

 export const fetchAcademyAll = () => {
  return async (dispatch) => {
    dispatch(fetchTableRequest());
    try {
      const response = await academyDomainsAll();

      /* const availabilityPromises = response.map(async (row) => {
        const availabilityResponse = await checkDomain(row.domain);
        return availabilityResponse.status;
      }); */
      //const availabilityResults = await Promise.all(availabilityPromises);
      const tableDataWithIndex = response.map((row, index) => ({
        ...row,
        index: index + 1, // Порядковый номер начинается с 1
        //isAvailable: availabilityResults[index],
      }));

      dispatch(fetchTableSuccess(tableDataWithIndex));
    } catch (error) {
      dispatch(fetchTableFailure(error.message));
    }
  };
};

export const updateTableData = (id, columnName, newValue) => {
  return async (dispatch) => {
    try{
      await updateAcademyAll(id, columnName, newValue);//api update
      dispatch(modifyTableData({ id, columnName, newValue }));//update in table
      //dispatch(fetchAcademyAll());//upgrade table from DB
    }
    catch (error){
      console.error('Error updating data:', error);
    }
  };
};

export const updateAllDomainsLocal = (id, columnName, newValue) => {
  return async (dispatch) => {
    try{
      dispatch(modifyTableData({ id, columnName, newValue }));//update in table
    }
    catch (error){
      console.error('Error updating data:', error);
    }
  };
};

export const addNewTableRow = (domain) => {
  return async (dispatch) => {
    try {
      await addNewAcademyAll(domain);
      dispatch(addTableRow(domain));
      dispatch(fetchAcademyAll());
    } catch (error) {
      console.error('Error adding new row:', error);
    }
  };
};

export const deleteByIdTableRow = (id) => {
  return async (dispatch) => {
    try {
      await deleteRowFromAcademyAll(id);
      dispatch(deleteTableRow({id: id}));
      dispatch(fetchAcademyAll());
    } catch (error) {
      console.error('Error adding new row:', error);
    }
  };
};

export const deleteAll = (id) => {
  return async (dispatch) => {
    try {
      await allAcademyDeleteAll(id);
      dispatch(fetchAcademyAll());
    } catch (error) {
      console.error('Error delete all:', error);
    }
  };
};
