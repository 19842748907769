import React, { useState, useEffect } from 'react';
import DomainManagerTable from '../components/tables/DomainManagerTableVirtual';
import MarketerTable from '../components/tables/MarketerTableVirtual';
import { useAuth } from '../contexts/AuthContext';
import {
  TextField,
  Button,
} from '@mui/material';
import { getMarketers, getTeamsList, getParentList } from '../services/api';
import { useSocket } from '../hooks/socketIO';

const ManagerPage = () => {
  const { user, /*loading*/ } = useAuth();
  const { socket } = useSocket();
  const [searchValue, setSearchValue] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [menuTeamOptions, setMenuTeamOptions] = useState([]);
  const [menuParentOptions, setMenuParentOptions] = useState([]);
  const [marketers, setMarketers] = useState([]);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const teams = await getTeamsList();
        const groups = await getParentList();
        setMenuTeamOptions(teams);
        setMenuParentOptions(groups);
      } catch (error) {
        console.error('Failed to fetch teams', error);
      }
    };

    fetchTeams();
  }, []);

  useEffect(() => {
    const fetchMarketers = async () => {
      try {
        const response = await getMarketers(user.team);
        const marketersList = response.map(user => user.username);
        console.log(marketersList);
        setMarketers(marketersList);
      } catch (error) {
        console.error('Ошибка при получении данных аккаунтов:', error);
      }
    };
  
    fetchMarketers();
  
  }, [user]);

  useEffect(() => {
    socket.emit('subscribeToTable', { target: user.team });
    socket.emit('subscribeToTable', { target: user.username });

    return () => {
      socket.emit('unsubscribeFromTable', { target: user.team });
      socket.emit('unsubscribeFromTable', { target: user.username });
    };
  }, [socket, user]);

  const activeButtonStyle = {
    backgroundColor: '#e9eaeb',
  };

  const updateTableData = () => {
    console.log("updateTableData");
    //fetchHistoryData();
  };


  return (
    <>
      <TextField
        fullWidth
        label="Global search"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        size="small"
        className={'global-search'}
        variant="filled"
      />
      {activeTab === 0 && 
      <DomainManagerTable
        tableName={"Manager"}
        updateData={updateTableData}
        currentUser={user.username}
        userTeam={user.team}
        userGroup={user.usergroup}
        searchValue={searchValue}
        menuTeamOptions={menuTeamOptions}
        menuParentOptions={menuParentOptions}
        marketers={marketers}
      />
      }
      {activeTab === "Private" && 
        <MarketerTable
        tableName={"Marketer"}
        updateData={updateTableData}
        currentUser={user.username}
        userTeam={user.team}
        userGroup={user.usergroup}
        searchValue={searchValue}
        menuTeamOptions={menuTeamOptions}
        menuParentOptions={menuParentOptions}
        marketers={marketers}
      />
      }
      <>
      <Button 
        color="inherit"
        size="medium"
        sx={{ height: '36px', ...(activeTab === 0 && activeButtonStyle) }}
        onClick={() => setActiveTab(0)}
      >
        Team
      </Button>
      <Button
        color="inherit"
        size="medium"
        sx={{ height: '36px', ...(activeTab === 'Private' && activeButtonStyle) }}
        onClick={() => setActiveTab('Private')}
      >
        Private
      </Button>
      </>
    </>
  );
};

export default ManagerPage;